<template>
  <div class="page">
    <div class="pageFucView">
      <div class="pageFucView_left"></div>
      <div class="pageFucView_right">
        <el-button class="button" type="primary" icon="el-icon-plus" @click="handleEdit" size="small">添加</el-button>
      </div>
    </div>
    <el-table :data="list">
      <el-table-column
          align="center"
          label="ID"
          prop="id"
          show-overflow-tooltip
          width="100"
      />
      <el-table-column
          align="center"
          label="编号"
          prop="sort_order"
          show-overflow-tooltip
      />
      <el-table-column
          align="center"
          label="类型名称"
          min-width="120"
          prop="name"
          show-overflow-tooltip
      />
      <el-table-column
          align="center"
          label="属性数量"
          min-width="120"
          prop="propertyCount"
          show-overflow-tooltip
      />
      <el-table-column
          align="center"
          label="参数数量"
          min-width="120"
          prop="paramsCount"
          show-overflow-tooltip
      />
      <el-table-column align="center" fixed="right" label="设置" width="160">
        <template #default="{ row }">
          <el-button type="text" size="small" @click="handleAttribute(row)">属性列表</el-button>
          <el-button type="text" size="small" @click="handleParameter(row)">参数列表</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="120">
        <template #default="{ row }">
          <el-button type="text" size="small" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" size="small" @click="handleDelete(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageView">
      <el-pagination layout="prev, pager, next" :page-size="searchObj.pageSize" :current-page.sync="searchObj.page" :total="pageCount" @current-change="pageChangeHandle" />
    </div>
        <Edit ref="edit" @fetch-data="getData" />
  </div>
</template>

<script>
import Edit from './components/Edit'
import {apiDelGoodsCategory, apiGetGoodsCategoryList} from "@/request/api";
export default {
  name: 'typelist',
  components: { Edit },
  data() {
    return {
      list: [],
      pageCount: 0,
      searchObj: {
        pageSize: 10,
        page: 1,
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    pageChangeHandle(){
      this.getData()
    },
    getData(){
      apiGetGoodsCategoryList(this.searchObj).then(res =>{
        if(res.code == 200){
          this.pageCount = res.data.count;
          this.list = res.data.list;
        }
      })
    },
    handleDelete(id){
      this.$confirm('是否删除该类型?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        apiDelGoodsCategory({id:id}).then(res => {
          if(res.code == 200){
            this.getData()
          }
        })
      }).catch(() => {
      });
    },
    //跳转属性列表
    handleAttribute(row) {
      this.$router.push({
        path: '/commoditytype/attributelist',
        query: {
          id: row.id,
        },
      })
    },
    //跳转参数列表
    handleParameter(row) {
      this.$router.push({
        path: '/commoditytype/parameterlist',
        query: {
          id: row.id,
        },
      })
    },
    //新增/编辑
    handleEdit(row) {
      console.log(row)
      if (row.id) {
        this.$refs['edit'].showEdit(row)
      } else {
        this.$refs['edit'].showEdit()
      }
    }
  },
}
</script>
<style>
.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
  margin-bottom: 80px;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
.pageFucView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pageFucView_right {
  display: flex;
}
</style>
