<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="550px"
      @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="类型名称" prop="name">
        <el-input v-model="form.name" size="small" />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="排序" prop="sort">
        <el-input type="number" v-model="form.sort" :min="1" size="small" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiCreateGoodsCategory} from "@/request/api";

export default {
  name: 'Edit',
  data() {
    return {
      form: {
        name: '',
        sort: 1,
      },
      rules: {
        name: [
          { required: true, trigger: 'blur', message: '请输入类型名称' },
        ],
        sort: [
          { required: true, trigger: 'blur', message: '请输入排序' },
        ],
      },
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {},
  methods: {
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '修改'
        this.form ={
          name: Object.assign({}, row).name,
          sort: Object.assign({}, row).sort_order,
          id:Object.assign({}, row).id
        }
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      var formData = this.form
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          console.log(valid)
          apiCreateGoodsCategory(formData).then(res =>{
            if (res.code == '200') {
              this.$message.success(res.message)
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$message.error(res.message)
              this.$emit('fetch-data')
              this.close()
            }
          })

        } else {
          return false
        }
      })
    },
  },
}
</script>
